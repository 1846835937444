// Import all plugins
import * as bootstrap from 'bootstrap';

$(document).ready(function() {
    $('a.nav-link.dropdown-toggle').click(function() {
       location.href = this.href;
    });
 });

 /*active class*/
$('#mobile-menu').click(function(){
   $(this).toggleClass('active');
   $('body').toggleClass('noscroll');
   return false;
 });
 
 $('#mobile-menu').click(function(){
   $('#overlay-menu').toggleClass('active');
   return false;
 });
 
 /*remove active class on menu li click*/
 $('#menu li').on('click', function(){
     $("#overlay-menu").removeClass("active");
     $("#mobile-menu").removeClass("active");
 });

